import { render, staticRenderFns } from "./okRenew.vue?vue&type=template&id=6807c024&scoped=true"
import script from "./okRenew.vue?vue&type=script&lang=js"
export * from "./okRenew.vue?vue&type=script&lang=js"
import style0 from "./okRenew.vue?vue&type=style&index=0&id=6807c024&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6807c024",
  null
  
)

export default component.exports